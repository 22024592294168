
header.header-section {
    z-index: $zindex-fixed;

    .btn-head {
        padding: 50px 35px 18px!important;
        color: $white;
        border-radius: 0 0 10px 10px;
        margin: 0 5px !important;
    }

    .btn-head:hover {
        background: $secondary;
        color: $primary;
    }

    .btn-head-blue {
        background: rgb(101,149,202);
        background: linear-gradient(300deg, rgba(101,149,202,1) 100%, rgba(68,119,175,1) 100%);
    }

    .btn-head-green {
        background: rgb(13,176,170);
        background: linear-gradient(300deg, rgba(13,176,170,1) 100%, rgba(2,135,130,1) 100%);
    }

    .btn-head-red {
        background: rgb(255,30,30);
        background: linear-gradient(300deg, rgba(255,30,30,1) 100%, rgba(197,3,3,1) 100%);
    }

    &.scrolling .container-fluid.py-30px.py-lg-40px.bg-white {
        padding: 15px 15px !important;
    }

    .container-fluid.py-30px.py-lg-40px.bg-white {
        transition: .3s ease;
    }

    @include media-breakpoint-down(lg) {
        .container {
            max-width: 100%;
        }
    }

    .phone-col {
        .mod {
            font-size: 16px;
            letter-spacing: 0.15em;
            padding: 15px 30px;
        }

        svg:not([class*="angle-down"]),
        i:not([class*="angle-down"]) {
            display: none !important;
        }

        ul[id*="rblPhones"] a {
            font-size: 12px !important;
            text-transform: uppercase;
            color: $primary !important;
            letter-spacing: 0.15em;
        }

        .phone-dropdown-menu ul, .address-dropdown-menu ul {
            padding: 0;
            margin: 0;
        }

        .phone-dropdown-menu ul a .row,
        .address-dropdown-menu ul a .row {
            padding: .5rem .25rem .5rem .5rem;
            position: relative;
        }

        .phone-dropdown-menu ul a,
        .address-dropdown-menu ul a {
            line-height: 1.4em;
            transition: 0.3s ease;
            opacity: 0;
            visibility: hidden;
            transform: translate3d(0, 1rem, 0);
            display: block;
        }
    }

    nav {
        .icons {
            .dropdown-menu {
                min-width: 15rem;
                border: none;
                box-shadow: 0px 2px 7px rgba(0,0,0,0.2);
                padding: 10px;

                div[id*="container"] a {
                    color: $primary !important;
                    font-size: 12px !important;
                    text-transform: uppercase;
                    letter-spacing: .15em;
                    font-weight: bold;
                    line-height: 1.5;
                    display: block;
                    padding: .5rem .25rem .5rem .5rem;
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        z-index: -1;
                        top: 5%;
                        bottom: 5%;
                        left: 5%;
                        right: 5%;
                        transition: .3s;
                        background-color: rgba(15,20,26,.1) !important;
                        opacity: 0;
                    }

                    &:hover {
                        &:before {
                            right: 0;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            opacity: 1;
                        }
                    }
                }

                div[id*="container"] span {
                    font-weight: 400;
                    color: #242220 !important;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            ul#desktopMenu {
                ul {
                    li {
                        a {
                            position: relative;

                            &::before {
                                content: '';
                                display: block;
                                position: absolute;
                                z-index: -1;
                                top: 5%;
                                bottom: 5%;
                                left: 5%;
                                right: 5%;
                                transition: .3s;
                                background-color: rgba($gray-200,0.15) !important;
                                opacity: 0;
                            }

                            &:hover {
                                &::before {
                                    opacity: 1;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                }
                            }

                            &.current {
                                &::before {
                                    opacity: 1;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .phone-dropdown-menu ul a,
    .address-dropdown-menu ul a {
        .row {
            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: -1;
                top: 5%;
                bottom: 5%;
                left: 5%;
                right: 5%;
                transition: 0.3s ease;
                background-color: rgba(15,20,26,0.1) !important;
                opacity: 0;
            }
        }

        &:hover {
            .row {
                &:before {
                    right: 0;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    opacity: 1;
                }
            }
        }
    }

    .phone-dropdown-menu,
    .address-dropdown-menu {
        transition: opacity 0.2s ease, visibility 0.2s ease, margin-top 0.2s ease;
        display: block;
        opacity: 0;
        visibility: hidden;
        padding: 1rem * 0.5;
        border: none;
        background: transparent;
        margin-top: -1.5rem !important;
        max-width: 100% !important;
        min-width: 16rem !important;
        margin-right: -5px;
        box-shadow: none !important;
        left: -2% !important;

        span[class*="col"] {
            color: #242220 !important;
        }

        span.phone-number {
            color: $primary !important;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            top: 5%;
            bottom: 25%;
            left: 25%;
            right: 25%;
            transition: 0.3s ease;
            border-radius: $border-radius;
            background-color: #f9f9f9 !important;
            box-shadow: $box-shadow;
            opacity: 0;
        }

        &.show {
            opacity: 1;
            visibility: visible;

            &:before {
                right: 0;
                left: 0;
                top: 0;
                bottom: 0;
                opacity: 1;
            }

            ul {
                @for $i from 1 to 10 {
                    a:nth-child(#{$i}) {
                        opacity: 1;
                        visibility: visible;
                        transform: translate3d(0, 0, 0);
                        transition-delay: 0.2 + ($i * 0.025s);
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    ul#desktopMenu {
        justify-content: end !important;
        > li {
            > a {
                position: relative;
                &.current,
                &.highlighted,
                &:hover {
                    &:before {
                        background: $secondary !important;
                        opacity: 1 !important;
                    }
                }
                &:before {
                    content: '';
                    position: absolute;
                    transition: .3s ease;
                    bottom: 0;
                    left: 5px;
                    right: 5px;
                    height: 2px;
                    border-radius: 0;
                    background: transparent;
                    opacity: 0;
                }
            }
            > a.has-submenu {
                position: relative;
                &.highlighted,
                &:hover {
                    &:before {
                        background: $primary !important;
                        opacity: 1 !important;
                    }
                }
                &:before {
                    content: '';
                    position: absolute;
                    transition: .3s ease;
                    bottom: 0;
                    left: 5px;
                    right: 5px;
                    height: 2px !important;
                    border-radius: 0;
                    background: transparent;
                    opacity: 0;
                }
            }
        }
    }
}

#desktopMenu>li .dropdown-menu .row>.col:not(:first-child) {
    border-left: 1px solid #4e4e4e !important;
}
#desktopMenu li ul {
    top: 52px !important;
}

#desktopMenu li ul li ul {
    top: auto !important;
}