/*
This is where you write custom SASS
*/

.color-dafault {
    color: #26282B !important;
}
h1 {
    color: $primary;
}
.hov-sec:hover {
    color: #00AEEF !important;
}
.cta {
    background: rgb(101,149,202);
    background: linear-gradient(300deg, rgba(101,149,202,1) 100%, rgba(68,119,175,1) 100%);
    border-radius: 10px;
    min-height: 230px;
    max-width: 335px !important;
    flex-direction: column;
    display: flex;
    margin: 0 auto;

    h4 {
        letter-spacing: .05em !important;
        color: $white;
    }

    img {
        height: 100px !important;
        object-fit: contain;
    }
}

.cta:hover {
    background: rgb(61,190,185);
    background: linear-gradient(300deg, rgba(61,190,185, 1) 100%, rgba(53,160,156,1) 100%);

    h4 {
    color: $white;
    }
}

.form-control{
    border-radius: 10px !important;
}

.btn-primary:hover {
    color: $primary !important;
}

.social {
    width: auto !important;
}

.contact-bar .mod .addr .brdr {
    border-right: 1px solid rgba(29,97,174,.5);
    font-size: 24px;
}

.navbar {
    background: $primary;
}

@media (min-width: 989px) {
    .navbar.desktop-navbar {
        background: transparent !important;
    }
}
