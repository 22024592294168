
section.services-section {
    .col-lg-3 {
        margin-bottom: 30px;
    }
        
    .mw {
        max-width: 1500px;
    }

    .wrap {
        align-items: center;
        justify-content: center;

        .h5 {
            color: #26282B;
            font-weight: 700;
            font-size: 24px;
            letter-spacing: 0.05em;
            text-transform: capitalize !important;
            line-height: 33px;
        }

        span {
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.15em;
            text-transform: capitalize !important;
        }
    }

    .wrap1 {
        padding: 49px 0;

        .h5 {
            color: #26282B;
            font-weight: 700;
            font-size: 24px;
            letter-spacing: 0.15em;
            text-transform: capitalize !important;
            line-height: 33px;
        }

        .l2 {
            line-height: 22px !important;
        }

        span {
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.15em;
            text-transform: capitalize !important;
        }
    }

    a:hover {
        .h5 {
            color: $primary;
            -webkit-transition: all .35s;
            -moz-transition: all .35s;
            transition: all .35s;
        }
    }
}
