section.hero-section {
    min-height: 400px;
    height: 857px;

    .mod {
        min-height: 400px;
        height: 857px;

        .display-1 {
            color: $white;
        }
    }

    @media screen and (max-width: 1600px) {
        & {
            height: 620px;
        }

        .mod {
            height: 620px;
        }
    }

    @media screen and (max-width: 1199px) {
        & {
            height: 63vw;
        }

        .mod {
            height: 63vw;
        }
    }

    .btn-white {
        color: white;
        background: transparent;
        padding: 14px 45px;
        text-transform: capitalize;
    }

    .btn-white:hover {
        border-color: $primary;
        background: $primary;
        color: $white;
    }
}
