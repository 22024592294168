section.testimonials-section {
    min-height: 850px;
    // border-bottom: 20px solid #fff;

    .mod1 .panel-image {
        min-height: 850px;
    }
    .wrap {
        padding: 215px 15px;
        min-height: 850px;
    }
    // .b-mod1 {
    //     border-left: 20px solid #fff;
    //     border-right: 10px solid #fff;
    // }
    // .b-mod2 {
    //     border-right: 20px solid #fff;
    //     border-left: 10px solid #fff;
    // }

    &.testimonials {
        blockquote {
            margin: 0 0 2rem;

            p {
                @include media-breakpoint-up(xxl) {
                    & {
                        font-size: 24px;
                        line-height: 36px;
                    }
                }
                @include media-breakpoint-down(xxl) {
                    font-size: 24px;
                    line-height: 36px;
                }
            }

            a {
                color: $primary;

                &:hover {
                    color: $secondary;
                }
            }

            cite {
                letter-spacing: 0.05em !important;
                font-size: 18px;
                font-weight: 700;
                color: $primary;

                &::before {
                    content: '-';
                    position: relative;
                    margin-right: 5px;
                }

                span {
                    display: inline !important;
                }
                :nth-child(2) {
                    display: none !important;
                }
                :nth-child(3) {
                    display: none !important;
                }
            }
        }
        .testimonial-wrapper {
            border-right: none !important;
            
            @media screen and (max-width: 991px) {
                & {
                    margin-bottom: 0px !important;
                    margin-top: 0 !important;
                }
            }
            @include media-breakpoint-up(xxl) {
                & {
                    padding: 40px 0 30px;
                }
            }
            @media screen and (max-width: 1720px) {
                & {
                    padding: 40px 0 30px;
                }

            }
            @include media-breakpoint-down(xl) {
                & {
                    padding: 40px 0 30px;
                }
            }
            @include media-breakpoint-down(sm) {
                & {
                    padding: 40px 0 30px;
                }
            }

        }
    }
}