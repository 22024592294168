section.contact-form {
    min-height: 850px;
    .mod1 .panel-image {
        min-height: 850px;
    }
    
    ::placeholder {
        font-weight: 200;
        text-transform: capitalize;
        letter-spacing: 0.05em;
        color: $primary;
    }

    .form-control {
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        letter-spacing: 0.15em;
        color: #929292;
        border-color: #ffffff;
    }

    .form-group {
        margin-top: 0px  !important;
        margin-bottom: 10px !important;
    }

    textarea {
        min-height: 142px;
    }
    
    [id*="btnSend"] {
        max-width: 196px;
    }
    .qc-form-wrapper {
        min-height: 850px;

        @include media-breakpoint-up(xxl) {
            & {
                padding: 140px 15px;
            }
        }
        @media screen and (max-width: 1720px) {
            & {
                padding: 140px 15px;
            }

        }
        @include media-breakpoint-down(xl) {
            & {
                padding: 140px 15px;
            }
        }
        @include media-breakpoint-down(sm) {
            & {
                padding: 140px 15px;
            }
        }
    }
    // @media screen and (max-width: 1800px) {
    //     .qc-form-wrapper {
    //         padding: 0;
    //     }
    // }
    // @media screen and (max-width: 1600px) {
    //     .qc-form-wrapper {
    //         padding: 0;
    //     }
    // }
    // @media screen and (max-width: 1199px) {
    //     & {
    //         padding-top: 100px;
    //     }
    // }
    // @media screen and (max-width: 991px) {
    //     .qc-form-wrapper {
    //         margin-bottom: 0;
    //         padding: 0;
    //     }
    // }
    // @media screen and (max-width: 767px) {
    //     .qc-form-wrapper {
    //         padding: 0;
    //     }
    // }
    &.no-services {
        @media screen and (min-width: 992px) {    
            & {
                padding-top: 300px !important;
            }
        }
        
        @media screen and (max-width: 991px) {   
            & {
                padding-top: 0px !important;
            } 
        }
        &.no-testimonials {
            padding-top: 30px !important;
        }
    }
}