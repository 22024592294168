
section.ctas {
    padding: 50px 0 0;

    [class*="btn"]:not(.no-fx):not([class*="owl"]):hover, .hover-fx:not([class*="owl"]):hover {
        transform: translate(0, -10px) !important;
        box-shadow: 0px 13px 40px rgba($color: #000000, $alpha: 0.2) !important;
    }
    .img-hover-zoom img {
        transition: all .3s ease-in-out;
    }
    
    .img-hover-zoom:hover img {
        // transform: scale(1.05);
        filter: drop-shadow inset(2px 4px 6px rgba(0, 0, 0, 0.3));
    }
    .mod-row {
        max-width: 1460px;
        margin: 0 auto;
    }
    .mod {
        .h4 {
            font-size: 24px;
            color: $primary;
            letter-spacing: 0.05em !important;
            font-weight: 700;
            transition: 0.3s;
        }
        a:hover {
            box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.5);

            .opacity-85 {
                opacity: .5 !important;
            }

            .h4 {
                color: $secondary;
            }
        }
    }
}

section.main {
    p {
        letter-spacing: 0.02em;
        line-height: 25px;
    }
}

section.map {
    margin: 0 0 -10px 0;
}

section.associations {
    text-align: center;
    padding: 100px 0;
}

footer.foot {
    br {
        display: none;
    }
    .info {
        .mod {
            .addr {
                letter-spacing: 0.05em;
                font-size: 22px;
                font-weight: 400;
                color: #ffffff;
    
                .brdr {
                    border-right: 1px solid rgba(255,255,255,.2);
                    font-size: 23px;
                }
                br {
                    display: none;
                }
                .social {
                    font-size: 18px;
                }
                a {
                    color: #ffffff;
    
                    &:hover {
                        color: $secondary;
                    }
                }
            }
        }
    }
    .btm {
        color: #BBBBBB;
        text-transform: uppercase;
        letter-spacing: 0.15em;
        font-size: 14px;
        font-weight: 400;

        a {
            color: $primary;

            &:hover {
                color: $secondary;
            }
        }
    }
}

.btn-outline-orange {
    color: $primary !important;
    border-color: $primary;
}

.btn-outline-orange:hover {
    color: #fff !important;
    border-color: $secondary;
    background-color: $secondary;
}

.btn-primary:hover {
    color: #fff;
    border-color: $secondary !important;
    background-color: $secondary !important;
}

.btn-primary {
    color: #fff !important;
}

.scroll-up {
    &:hover {
        box-shadow: none !important;
    }
}

.text-initial {
    text-transform: initial;
}

//interior custom scss
body.interior {
    section[id*="interiorBanner"] {
        height: 26vw;
        // min-height: 300px;
        // @media screen and (max-width: 1600px) {
        //     & {
        //         height: 40vw;
        //     }
        // }
    }

    &.page-type-3 {
        .card {
            padding: 35px;
            background: $secondary;
            color: $primary;
            border-radius: 10px;

            p.h3 {
                text-transform: capitalize;
                font-size: 50px;
                font-weight: 700;
                letter-spacing: .05 em;
                margin-bottom: 15px;
            }

            [id*="HeaderInfoDiv"] {
                font-size: 16px;
                font-weight: 200;
                letter-spacing: 0.05em;
                color: $primary;
            }

            ::placeholder {
                font-size: 16px;
                font-weight: 200;
                text-transform: capitalize;
                letter-spacing: 0.05em;
                color: $primary;
            }

            .form-control {
                font-size: 16px;
                font-weight: 200;
                text-transform: capitalize;
                letter-spacing: 0.05em;
                color: $primary;
            }

            label {
                display: none;
            }

            textarea {
                min-height: 142px;
            }

            .text-muted {
                color: #707070 !important;
            }
        }
    }

    &.page-type-26 {
        .page-content .container {
            max-width: 960px;
        }
    }

    &.page-type-40 {
        .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
            background: $primary !important;
        }

        .owl-theme .owl-dots .owl-dot span {
            background: rgba($color: $primary, $alpha: 0.25) !important;
        }
    }
}

[class*="btn"]:not(.no-fx), .hover-fx {
    transition: 0.3s ease !important;
}
[class*="btn"]:not(.no-fx):not([class*="owl"]):hover, .hover-fx:not([class*="owl"]):hover {
    transform: translate3d(0, -2px, 0);
    box-shadow: 0px 13px 40px rgba($color: #000000, $alpha: 0.2);
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

// Apointment Request Form

.list-group.mb-5 {
    >.list-group.mb-5 {
        >a:first-child {
            border-top-right-radius: 0 !important;
            border-top-left-radius: 0 !important;
        }

        ~a.list-group-item.list-group-item-action+a:nth-last-child(2),
        +a {
            border-top-right-radius: $border-radius !important;
            border-top-left-radius: $border-radius !important;
        }

        ~a.list-group-item.list-group-item-action+a:nth-last-child(3) {
            border-bottom-right-radius: $border-radius !important;
            border-bottom-left-radius: $border-radius !important;
            margin-bottom: 3rem;
        }
    }

    .wysiwyg>& {
        >a.list-group-item.list-group-item-action>.text-muted {
            font-weight: bold !important;
            color: $list-group-action-color !important;
        }
    }
}

.modal-open {
    overflow: auto;
}

.opacity-100 {
    opacity: 1 !important;
}

.opacity-90 {
    opacity: 0.9 !important;
}

.opacity-85 {
    opacity: 0.85 !important;
}

.opacity-75 {
    opacity: 0.75 !important;
}

.opacity-50 {
    opacity: 0.50 !important;
}

.opacity-25 {
    opacity: 0.25 !important;
}

.opacity-10 {
    opacity: 0.1 !important;
}

.opacity-0 {
    opacity: 0 !important;
}

.extra-small {
    font-size: $extra-small-font-size !important;
    line-height: $small-line-height !important;
}

.navbar-brand {
    font-family: $navbar-brand-font-family !important;
    white-space: normal;
}

blockquote {
    font-family: $blockquote-font-family;
    font-size: $blockquote-font-size;
    font-style: $blockquote-font-style;
    line-height: $blockquote-line-height;
    letter-spacing: $blockquote-letter-spacing;
    font-weight: $blockquote-font-weight;

    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
        font-size: $font-size-lg !important;
        line-height: $lead-line-height !important;
    }
}

html {
    margin: 0 !important;
    overflow-x: hidden;
}

hr {
    width: 100%;
}

img {
    max-width: 100%;
    height: auto !important;
}

label {
    font-size: $label-font-size;
    line-height: $label-line-height;
    letter-spacing: $label-letter-spacing;
    font-weight: $label-font-weight;
    text-transform: $label-text-transform;
}

body {
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.02em;
    overflow-x: hidden;
    line-height: 1.6em;
    transition: opacity 0.85s ease 0.3s;
}

a {
    transition: 0.4s ease;
    text-decoration: none !important;
}

[data-brand*="fa-yelp"] {
    background-color: #af0606;
}

[data-brand*="fa-instagram"]:not([class*="bg-"]) {
    background: linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
}

[data-brand*="fa-facebook"] {
    background-color: #3b5998;
}

[data-brand*="fa-twitter"] {
    background-color: #1da1f2;
}

[data-brand*="fa-youtube"] {
    background-color: #ff0000;
}

[data-brand*="fa-linkedin"] {
    background-color: #0077b5;
}

[data-brand*="fa-google"] {
    background-color: #4285f4;
}

[data-brand*="fa-google-plus"] {
    background-color: #dd4b39;
}

.social-media>a:not([href]) {
    display: none !important
}

.owl-carousel {

    .owl-stage-outer {
        z-index: 1;

    }

    .owl-nav {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .owl-prev,
    .owl-next {
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0);
        opacity: 1;
        transition: 0.4s ease;
        &:focus {
            outline: none;
        }

        &[class*="-next"] {
            right: 0;
            left: auto;
        }
    }

    .owl-dots {
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        bottom: 0;
        opacity: 1;

        .owl-dot {
            transition: 0.4s ease;
            &:focus {
                outline: none;
            }
            span {
                transition: 0.4s ease;
                &:focus {
                    outline: none;
                }
            }
        }
    }
}

.panel-image-overlay {
    transition: 0.3s ease;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.panel-image-overlay.gradient.bottom-right {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100 */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 1)));
    /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* IE10 preview */
    background: linear-gradient(135deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=1);
    /* IE6-8 fallback on horizontal gradient */
}

.panel-image-overlay.gradient.bottom-left {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100 */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMTAwJSIgeDI9IjEwMCUiIHkyPSIwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(0, 0, 0, 0)));
    /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* IE10 preview */
    background: linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=1);
    /* IE6-8 fallback on horizontal gradient */
}


.panel-image-overlay.gradient.top {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100 */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(0, 0, 0, 0)));
    /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* IE10 preview */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=0);
    /* IE6-8 */
}

.container-fluid.container-max-width {
    max-width: map-get($container-max-widths, xl);
}

.sticky {
    position: relative;
    > div {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }
}

#pageOverlay, .modal-backdrop {
    background: $backdrop-color;
    position: fixed;
    z-index: 1048;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease;
}

.navbar-expand-xl {
    @include media-breakpoint-down(lg) {
        .modal-backdrop.show {
            opacity: 1;
            visibility: visible;
        }
    }
}
.navbar-expand-lg {
    @include media-breakpoint-down(md) {
        .modal-backdrop.show {
            opacity: 1;
            visibility: visible;
        }
    }
}
.navbar-expand-md {
    @include media-breakpoint-down(sm) {
        .modal-backdrop.show {
            opacity: 1;
            visibility: visible;
        }
    }
}
.navbar-expand-sm {
    @include media-breakpoint-down(xs) {
        .modal-backdrop.show {
            opacity: 1;
            visibility: visible;
        }
    }
}

.modal {
    transition: opacity 0.4s ease, visibility 0.4s ease;
    opacity: 0;
    visibility: hidden;
    display: block !important;

    .modal-dialog {
        transition: 0.4s ease;
        opacity: 0;
        transform: translate3d(0, -2.5rem, 0) !important;
    }
}
.mobile-nav-show #pageOverlay,
.modal.show {
    opacity: 1;
    visibility: visible;

    .modal-dialog {
        opacity: 1;
        transform: translate3d(0, 0, 0) !important;
    }
    + .modal-backdrop {
        opacity: 1;
        visibility: visible;
    }
}


html {
    .panel-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        height: 100%;
        box-sizing: content-box;
        ~ * {
            position: relative;
        }
        video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%,-50%,0);
        }
    }

    @include media-breakpoint-up(lg) {
        .parallax {
            overflow: hidden;
            position: relative;
        }

        .parallax .panel-image {
            transition: none !important;
            animation: none !important;
        }

        .parallax>.panel-image,
        .parallax .slides li>.panel-image {
            height: 100%;
            transition: 0 !important;
        }

        @media all and (max-width: map-get($grid-breakpoints, md) - 1) {

            // .parallax>.panel-image,
            // .parallax .slides li>.panel-image {
            // }

            .panel-image,
            .slides li>.panel-image {
                top: 0 !important;
                transform: none !important;
                padding: 0;
            }
        }

        &:not([data-browser-type*="InternetExplorer"]),
        &:not([data-browser-type*="Firefox"]),
        &:not([data-user-agent*="Edge"]) {

            .parallax>.panel-image {
                min-height: 100vh;
            }

            .parallax>.panel-image,
            .parallax .slides li>.panel-image {
                top: -50vh;
            }

            .parallax:first-child .slides li>.panel-image,
            .parallax:first-child .panel-image {
                top: 0;
            }

            .main-container>a:first-child+.parallax .panel-image {
                top: 0;
            }
        }

        &[data-user-agent*="Edge"],
        &[data-user-agent*="iPad"],
        &[data-user-agent*="iPhone"],
        &[data-user-agent*="Android"],
        &[data-browser-type*="Firefox"] {
            .panel-image {
                // min-height: 1px;
                transform: none !important;
                top: initial !important;
                // min-height: 1px !important;
            }

            .parallax>.panel-image,
            .parallax .slides li>.panel-image {
                top: 0;
            }
        }

        &[data-browser-type*="InternetExplorer"] {
            .modal {
                .modal-dialog-centered {
                    min-height: 100% !important;
                    margin-top: 0;
                    margin-bottom: 0;

                    .modal-content {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate3d(-50%, -50%, 0);
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        .parallax > .panel-image {
            transform: none !important;
            top: initial !important;
            min-height: 1px !important;
        }
    }
}

.ken-burns {
    animation: kenBurns 14s ease alternate infinite;
}

@keyframes kenBurns {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}

// Apointment Request Form


.list-group-item-action {
    overflow: hidden;

    [type="radio"],
    [type="checkbox"] {
        display: none;

        &~label {
            display: block;
            margin: 0;
            padding: 0.5rem 1rem;
            cursor: pointer;
            transition: padding 0.2s ease;
        }

        &:checked~label {
            background: map-get($theme-colors, primary);

            @if (lightness(map-get($theme-colors, primary)) > 65) {
                color: map-get($theme-colors, dark);
            }

            @else {
                color: $white;
            }

            padding-left: 1.5rem;
        }
    }
}

[data-page-type="carecredit"] header,
[data-page-type="carecredit"] #quickLinks {
    background-color: map-get($theme-colors, dark) !important;
}

.faq-list {
    .list-group-item {
        background-color: transparent;
    }
}

@import "navbar";

footer {
    @include media-breakpoint-up(xl) {
        &.position-xl-fixed {
            bottom: 0; 
            left: 0; 
            right: 0; 
            z-index: -1;
        }
    }
    @include media-breakpoint-up(lg) {
        &.position-lg-fixed {
            bottom: 0; 
            left: 0; 
            right: 0; 
            z-index: -1;
        }
    }
    @include media-breakpoint-up(md) {
        &.position-md-fixed {
            bottom: 0; 
            left: 0; 
            right: 0; 
            z-index: -1;
        }
    }
    @include media-breakpoint-up(sm) {
        &.position-sm-fixed {
            bottom: 0; 
            left: 0; 
            right: 0; 
            z-index: -1;
        }
    }
}

@include media-breakpoint-up(xl) {
    .position-xl-absolute {
        position: absolute !important;
    }
    .position-xl-fixed {
        position: fixed !important;
    }
    .position-xl-relative {
        position: relative !important;
    }
}

@include media-breakpoint-up(lg) {
    .position-lg-absolute {
        position: absolute !important;
    }
    .position-lg-fixed {
        position: fixed !important;
    }
    .position-lg-relative {
        position: relative !important;
    }
}

@include media-breakpoint-up(md) {
    .position-md-absolute {
        position: absolute !important;
    }
    .position-md-fixed {
        position: fixed !important;
    }
    .position-md-relative {
        position: relative !important;
    }
}

@include media-breakpoint-up(sm) {
    .position-sm-absolute {
        position: absolute !important;
    }
    .position-sm-fixed {
        position: fixed !important;
    }
    .position-sm-relative {
        position: relative !important;
    }
}

@include media-breakpoint-up(xs) {
    .position-xs-absolute {
        position: absolute !important;
    }
    .position-xs-fixed {
        position: fixed !important;
    }
    .position-xs-relative {
        position: relative !important;
    }
}

[data-toggle="collapse"] {
    .if-open {
        display: inline-block;
    }
        .if-closed {
            display: none;
        }
    &.collapsed {
        .if-open {
            display: none;
        }
        .if-closed {
            display: inline-block;
        }
    }
}

[data-scroll-reveal-after] {
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease;
    &.show {
        opacity: 1;
        visibility: visible;
    }
}

.financing-page {
    &, > * {
        padding-top: 0 !important;
    }
}

@include media-breakpoint-down(sm) {
    .quick-contact-lite-layout-3 textarea {
        height: auto !important;
    }
}

/*========================= Media Queries ========================*/

@include media-breakpoint-down(xl) {
    header.header-section .phone-col .mod {
        font-size: 15px !important;
    }
}

@include media-breakpoint-down(lg) {
    section.testimonials-section .wrap {
        min-height: auto !important;
    }
    header.header-section .phone-col .mod {
        font-size: 13px !important;
        min-width: auto !important;
        padding: 15px 25px !important;
    }
    section.hero-section .mod .display-1 {
        font-size: 68px !important;
        line-height: 70px !important;
    }
    section.hero-section .mod .display-4 {
        font-size: 70px !important;
    }
    section.ctas .mod .h4 {
        font-size: 21px !important;
    }
    section.services-section .wrap1 {
        padding: 45px 0 !important;
    }
    section.services-section .wrap .h5, section.services-section .wrap1 .h5 {
        line-height: 28px !important;
        font-size: 20px !important;
    }
    section.testimonials-section .wrap {
        padding: 150px 15px !important;
    }
    section.tagline-section .top {
        font-size: 21px !important;
    }
    footer.foot .top {
        font-size: 21px;
    }
}

@include media-breakpoint-down(md) {
    section.ctas .mod .h4, section.tagline-section .top {
        font-size: 16px !important;
    }
    section.hero-section .mod .display-1 {
        font-size: 52px !important;
        line-height: 55px !important;
    }
    section.hero-section .mod .display-4 {
        font-size: 58px !important;
    }
    footer.foot .btm {
        line-height: 32px !important;
    }
    section.testimonials-section .wrap {
        padding: 125px 15px !important;
    }
    section.services-section .mw {
        max-width: 290px !important;
    }
    section.contact-form, section.contact-form .mod1 .panel-image, section.contact-form .qc-form-wrapper, section.testimonials-section, section.testimonials-section .mod1 .panel-image {
        min-height: 88vw !important;
    }
    footer.foot .info .mod {
        line-height: 43px;
        font-size: 18px;
    }
    footer.foot .info .mod span {
        display: block;
    }
    footer.foot .info .mod .separator {
        background: none;
        width: 0;
        height: 0;
    }
    nav.navbar .social {
        padding-left: 29px;
    }
    section.tagline-section .top {
        line-height: 35px;
    }
    section.hero-section .mod {
        height: 650px;
    }
    section.associations .hover-fx {
        width: 290px;
        margin: 0 auto 30px !important;
    }
    section.associations .d-flex {
        display: block !important;
    }
    footer.foot .mod {
        line-height: 35px;
    }
}

@include media-breakpoint-down(sm) {
    section.ctas .mod .h4, section.tagline-section .top {
        font-size: 21px !important;
    }
    .galleryButtons .btn {
        margin-bottom: 5px;
    }
}

@include media-breakpoint-down(xs) {
    section.hero-section .mod .display-1 {
        font-size: 33px !important;
        line-height: 36px !important;
    }
    section.hero-section .mod .display-4 {
        font-size: 38px !important;
    }
    section.slider .mod {
        height: 600px;
    }
    section.slider .wrap {
        padding: 0 15px;
    }
}

@media (max-width: 480px) {
    section.hero-section .mod .display-1 {
        font-size: 33px !important;
        line-height: 36px !important;
    }
    section.hero-section .mod .display-4 {
        font-size: 28px !important;
    }
}
@media (max-width: 320px) {
    section.associations .hover-fx {
        width: 100% !important;
    }
}
